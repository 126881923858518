<template>
  <div :id="id" style="height: 100%, width: 100%">
    <b-modal v-model="modalShow" size="ls" @shown="initModal" :title="labelTitle" footer-class="footerClass"
      @hidden="hidden"
      content-class="shadow" no-close-on-backdrop
    >
      <b-alert dismissible fade :show="showError" variant="success" @dismissed="dismissAlert">
        <font-awesome-icon :icon="['far', 'check']"/>
        &nbsp;&nbsp;{{ alertMsg }}
      </b-alert>
      
      <b-form-group :label="$t('calendar.country')">
        <b-form-select v-model="country" :options="countries" @change="selectRegion"/>
      </b-form-group>
      <b-form-group :label="$t('calendar.region')">
        <b-form-select v-model="region" :options="countryRegions"/>
      </b-form-group>
      <b-form-group :label="$t('calendar.year')">
        <b-row>
           <b-col>
             <label>{{ $t('calendar.field.from') }}</label>
             <b-form-select v-model="yearFrom" :options="years"/>
          </b-col>
           <b-col>
             <label>{{ $t('calendar.field.to') }}</label>
            <b-form-select v-model="yearTo" :options="years"/>
          </b-col>
        </b-row>
      </b-form-group>
      <b-btn :disabled="country === null || yearFrom === null || yearTo === null || (yearTo < yearFrom)" class="mb-2" @click="search">{{ $t('button.search') }}</b-btn>
      
      <ag-grid-vue style="width: 100%;" class="ag-theme-balham holiday-selector-grid-height" id="holidaySelectorGrid"
            :gridOptions="holidayGridOptions"
            @grid-ready="onHolidayGridReady"
            :autoGroupColumnDef="autoHolidayGroupColumnDef"
            :columnDefs="columnDefs"
            :overlayLoadingTemplate="overlayLoadingTemplate"
            :defaultColDef="defaultColDef"
            :rowData="rowData"
            suppressContextMenu
            suppressRowSelection
            suppressCellSelection
            suppressMultiSort
            >
     </ag-grid-vue>

      <template v-slot:modal-footer="{ cancel }" style="justify-content: start;">
        <b-button :disabled="disableOk" size="sm" variant="success" @click="ok">{{ $i18n.t('button.ok') }}</b-button>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $i18n.t('button.cancel') }}</b-button>
      </template>
    </b-modal>
    
  </div>
</template>

<script>
import 'ag-grid-enterprise';
import { AgGridVue } from 'ag-grid-vue';
import { cloneDeep } from 'lodash';
import { strRandom, getHolidayCountries, getHolidays } from '@/helpers';

export default {
  name: 'HolidaySelectorModal',
  components: {
    'ag-grid-vue': AgGridVue
  },
  props: {
    title: { 
      type: String,
      default: null
    },
    show: {
      type: Boolean,
      required: true
    }
  },
  data: function() {
    return {
      permissionName: 'STORAGE_FILE',
      id: `HOLIDAY_LIST_${strRandom(5)}`,
      holidayGridOptions: null,
      fileGridOptions: null,
      columnApi: null,
      holidayGridApi: null,
      autoHolidayGroupColumnDef: null,
      columnDefs: null,
      defaultColDef: null,
      rowData: null,
  
      modalShow: false,
      selectedHoliday: [],

      holidayId: null,
      selectedHolidayName: null,
      alertMsg: null,
      path: [],
      countries: [],
      countryRegions: [],
      regions: {},
      years: [],
      country: null,
      yearFrom: null,
      yearTo: null,
      region: null,
      allData: null,
      dataYearFrom: null,
      dataYearTo: null
    };
  },
  beforeMount() {
    this.holidayGridOptions = {
      rowSelection: 'single'
    };

    this.defaultColDef = {
      sortable: true,
      resizable: true,
      minWidth: 100,
      menuTabs: []
    };

    this.columnDefs = [{
      headerName: this.$t('calendar.field.name'),
      field: 'name'
    },
    {
      headerName: this.$t('calendar.date'),
      field: 'date'
    }];
  },
  mounted() {
    this.$nextTick(()=>{
      window.addEventListener('orientationchange', this.detectOrientationChange);
    });

    const self = this;
    this.holidayGridOptions.onRowClicked = function(event) {
      self.holidaySelectionChanged(event);
    }

    this.holidayGridOptions.onColumnVisible = function(event) {
      event.api.sizeColumnsToFit();
    }
    
    getHolidayCountries().then((response) => {  
      const data = response.data;
      for (let i = 0; i < data.countries.length; i++) {
        self.countries.push({ value: data.countries[i].code, text: data.countries[i].name});
        self.countries.sort((a, b) => {
          if (a.text < b.text) {
            return -1;
          }
          else if (a.text > b.text) {
            return 1;
          }
          return 0;
        });
        self.regions[data.countries[i].code] = data.countries[i].subdivisions;
      }
      
    })
    .catch(() => {
      self.countries = [];
    });
    
    for (var year = 2019; year < 2051; year++) {
      this.years.push(year);
    }
  },
  create() {
    this.updateModalShow(this.show);
  },
  beforeDestroy() {
    window.removeEventListener('orientationchange', this.detectOrientationChange);
  },
  watch: {
    show(newValue) {
      this.updateModalShow(newValue);
      this.rowData = null;
      this.country = null;
      this.countryRegions = null;
      this.region = null;
      this.yearFrom = null;
      this.yearTo = null;
      this.allData = null;
      this.dataYearFrom = null;
      this.dataYearTo = null;
    }
  },
  computed: {
    showError() {
      return this.alertMsg != null;
    },
    overlayNoRowsTemplate() {
      return `<span class='grid-overlay'>${ this.$i18n.t('error.grid_data_loading') }</span>`;
    },
    overlayLoadingTemplate() {
      return `<span class='grid-overlay'><div class="mr-1 spinner-grow spinner-grow-sm text-dark"></div>${ this.$t('dataview.grid.loading') }</span>`;
    },
    disableOk() {
      return this.rowData === null;
    },
    labelTitle() {
      return this.title? this.title : this.$t('calendar.import_lookup');
    }
  },
  methods: {
    regionChanged() {
      if (this.allData !== null) {
        this.rowData = this.allData.filter(d => d.subdivisions.length === 0 || (d.subdivisions.length > 0 && d.subdivisions.includes(this.region)));
      }
    },
    selectRegion() {
      this.countryRegions = [];
      this.allData = null;
      this.rowData = null;
      this.region = null;
      const list = this.regions[this.country];
      for (const region of list) {
        this.countryRegions.push({value: region.code, text: region.name});
      }
      
      this.countryRegions.sort(function (a, b) {
        return a.text.toLowerCase().localeCompare(b.text.toLowerCase());
      });
      
      if (this.countryRegions.length !== 0) {
        this.region = this.countryRegions[0].value;
      }
    },
    holidaySelectionChanged(event) {
      const self = this;
      self.selectedHoliday = typeof event.data !== 'undefined' ? [{uuId: event.data.uuId, name: event.data.name}] : [];
      let rowNode = self.selectedHoliday.length !== 0 ? self.holidayGridApi.getRowNode(self.selectedHoliday[0].uuId) : null;
      if(rowNode) {
        rowNode.setSelected(true);
      }
    },
    onHolidayGridReady(params) {
      this.holidayGridApi = this.holidayGridOptions.api;
      this.holidayGridColumnApi = this.holidayGridOptions.columnApi;
      this.holidayGridApi.showNoRowsOverlay();
    },
    async search() {
      const self = this;
      if (this.allData === null ||
          this.dataYearFrom !== self.yearFrom ||
          this.dataYearTo !== self.yearTo) {
        this.allData = [];
        this.dataYearFrom = self.yearFrom;
        this.dataYearTo = self.yearTo;
        this.holidayGridApi.showLoadingOverlay();
        for (let year = self.yearFrom; year <= self.yearTo; year++) {
          const retData = await getHolidays(self.country, year, self.region).then((response) => {  
            const data = response.data;
            return data.holidays;
            
          })
          .catch(() => {
            self.rowData = [];
            self.holidayGridApi.showNoRowsOverlay();
          });
          self.allData.push(...retData);
        }
      }        
      self.regionChanged();
    },
    detectOrientationChange() {
      setTimeout(() =>{
        if(this.holidayGridApi) {
          this.holidayGridApi.sizeColumnsToFit();  
        }
      }, 100);
    },
    initModal() {
      this.path = cloneDeep(this.currentPath);
      this.holidayGridApi.sizeColumnsToFit();
    },
    reloadHolidayData() {
      this.holidayGridApi.refreshServerSide({ purge: true });
    },
    dismissAlert() {
      this.alertMsg = null;
    },
    httpAjaxError(e) {
      const response = e.response;
      if (response && 403 === response.status) {
        this.alertMsg = this.$i18n.t('error.authorize_action');
        
      } else if (response && 422 === response.status) {
        this.alertMsg = this.$i18n.t('error.attention_required');
        const feedback = response.data[response.data.jobCase][0];
        this.errors.add({
          field: `file.${feedback.spot}`,
          msg: this.$i18n.t(`error.${feedback.clue}`)
        })
      } else {
        this.alertMsg = this.$i18n.t('error.internal_server');
      }
      this.scrollToTop();
    },
    scrollToTop() {
      document.querySelector(`#${this.id}`).scrollIntoView();
    },
    updateSelected(newValue) {
      this.selectedHoliday.splice(0, this.selectedHoliday.length, ...newValue);
    },
    ok() {
      if (this.rowData === null) {
        return;
      }
      const payload = this.rowData.map(d => { return { uuId: `EXCEPTION_${strRandom(5)}`, name: d.name, date: d.date }});
      this.$emit('success', payload);
      this.$emit('update:show', false);
    },
    updateModalShow(newValue) {
      this.modalShow = newValue;
    },
    hidden() {
      this.$emit('update:show', false);
    },
  }
}


</script>

<style lang="scss" scroped>
.holiday-selector-grid-height {
  height: 350px;
  min-height: 350px;
}

#holidaySelectorGrid .ag-row-selected::before {
  background-color: transparent;
}
</style>