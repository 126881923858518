var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { height: "100%, width: 100%" } },
    [
      _c(
        "b-modal",
        {
          attrs: {
            title: _vm.title,
            "content-class": "shadow",
            "no-close-on-backdrop": "",
          },
          on: { ok: _vm.ok, hidden: _vm.closeModal },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function ({ ok, cancel }) {
                return [
                  _c(
                    "b-button",
                    {
                      attrs: {
                        disabled:
                          _vm.enumCode === null ||
                          _vm.enumCode.trim().length == 0 ||
                          _vm.isCodeDuplicated ||
                          _vm.enumCode.trim() == _vm.selectedCode,
                        size: "sm",
                        variant: "success",
                      },
                      on: {
                        click: function ($event) {
                          return ok()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.ok")))]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.cancel")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.enumShow,
            callback: function ($$v) {
              _vm.enumShow = $$v
            },
            expression: "enumShow",
          },
        },
        [
          _vm.isMissingKeyData
            ? [
                _c("div", { staticClass: "modal-message-overlay" }, [
                  _c("span", { staticClass: "grid-overlay" }, [
                    _vm._v(_vm._s(_vm.$t("enum.error_missing_key_data"))),
                  ]),
                ]),
              ]
            : [
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      { attrs: { cols: "12" } },
                      [
                        _c(
                          "b-form-group",
                          {
                            staticClass: "mb-0",
                            attrs: { label: _vm.$t("value") },
                          },
                          [
                            _c(
                              "b-input-group",
                              [
                                _c("b-form-input", {
                                  ref: "enumCode",
                                  class: { "is-invalid": _vm.isCodeDuplicated },
                                  attrs: { maxlength: "25" },
                                  on: { keyup: _vm.enumCodeKeyUp },
                                  model: {
                                    value: _vm.enumCode,
                                    callback: function ($$v) {
                                      _vm.enumCode = $$v
                                    },
                                    expression: "enumCode",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "b-form-invalid-feedback",
                              {
                                staticClass: "alert-danger form-field-alert",
                                class: { "d-block": _vm.isCodeDuplicated },
                                staticStyle: { "margin-top": "1px" },
                              },
                              [
                                _c("font-awesome-icon", {
                                  attrs: {
                                    icon: ["far", "circle-exclamation"],
                                  },
                                }),
                                _vm._v(
                                  "  " +
                                    _vm._s(
                                      _vm.$t(
                                        "task.error.task_name_already_exists"
                                      )
                                    ) +
                                    " "
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }